.default-color {
  color: #878787; }

.yellow-color {
  color: #ffbe30; }

.orange-color {
  color: #ff3e00; }

.black-color {
  color: #333333; }

.white-color {
  color: #ffffff; }

.gray-deep-color {
  color: #f0f0f0; }

.gray-light-color {
  color: #f7f7f7; }

.bg-default {
  background-color: #878787; }

.bg-yellow {
  background-color: #ffbe30; }

.bg-orange {
  background-color: #ff3e00; }

.bg-black {
  background-color: #333333; }

.bg-white {
  background-color: #ffffff; }

.bg-gray-deep {
  background-color: #f0f0f0; }

.bg-gray-light {
  background-color:white; }

.overlay-black {
  background: rgba(51, 51, 51, 0.5); }

.overlay-white {
  background: rgba(255, 255, 255, 0.5); }
  .imgbannersize1{
    width: 50%;
    display: flex;
    position: relative;
  
  }
  .imgbannersize2{
    width: 51%;
    display: flex;
    position: relative;
    /* top: -63px; */
    left: 166px;
    bottom: 63px;
    z-index: 100;
  }
  .imgbannersize3{
    width: 60%;
      display: flex;
      position: relative;
      bottom: 135px;
      height: 207px;
      left: 261px; 
  
  }
  .textcenter{
    display: flex;
    justify-content: center;
  }
  .colorWhi{
    color: white !important;
  }
  .padding10{
    padding: 10px;
  }
  .imageheiwei{
    width: 100%;
    height: 360px;
  }
  .bannerIMAGES1 {
    background-image: linear-gradient(
        120deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      url("../../assets/jpeg-optimizer_img11.webp");
   
    animation: shrink 4s infinite alternate;
  }
  @keyframes shrink {
    0% {
      background-size: 115% 115%;
    }
    100% {
      background-size: 100% 100%;
    }
  }
  .sliderimag{
    display: flex;
    justify-content: center;
    text-align: justify;
    max-height:300px;
    height:100%;
    border:4px solid #eee;
    padding:20px;
    font-size:16px;
    overflow-y:scroll;
  }
  
  @media (max-width:700px){
    .featured-icon-box{ 
  display: none;
    }
    .formitem{
        padding-top: 15px;
    }
    .slider-item-content {
      padding: 20px 0px;
  }
  
  .homebanner .swiper-button-prev {
    display: none;
  }
  .homebanner .swiper-button-next {
    display: none;
  }
  .peramuntform{
    width: 100% !important;
   
  }
  .imageheiwei{
   height: auto; 
  }
  .sliderouter{
    padding: 100px 0px 20px 0px;
  }
  }
  .menuefontcolor{
    color: black !important;
  }
  
  .peramuntform{
    width: 85%;
    border-radius: 25px;
  }
  .image-banner{
    background-size: cover !important;
    background-position: 57%;
  }
  .imagee{
    background-size: cover;
    background-repeat: no-repeat;
  }

.swiper2{
  background-size: cover;
  background-repeat: no-repeat;
}

.textcenter{
  font-family: 'Vollkorn', serif !important;
}

.slider-banner{
  background: linear-gradient(to right, rgba(15, 15, 15, 0.2), rgba(24, 23, 23, 0.2));
}
.first-banner{
  background-image: url("../../assets/imageWebp/wynd\(5\).webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  object-fit: contain;
  background-position: cover;
}
.third-banner{
  background-image: url("../../assets/imageWebp/wynd\(4\).webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  object-fit: contain;
  background-position: cover;
}
.second-banner{
  background-image: url("../../assets/imageWebp/wynd\(10\).webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  object-fit: contain;
  background-position: cover;
}
@media (max-width:700px){
  .banner-title{
    font-size: 28px !important;
    line-height: 42px !important;
  }
  .overlay-black{
    height: 500px;
  }
}