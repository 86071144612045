.paramounte1{
    
        width: 100%;
        max-height: 596px;
        padding-top: 81px ;
}
.gallerysec .swiper {
    /* width: 100%; */
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .gallerysec .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 400px;
    height: 300px;
  }
  
  .gallerysec .swiper-slide img {
    display: block;
    width: 100%;
  }
  

/* .imageheiwei {
        height: 360px;
        width: 100% !important;
    }
    
    img, svg {
        vertical-align: middle !important;
    }
    img {
        max-width: 100% !important;
    } */