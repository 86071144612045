.contact-info{
    padding: 117px 0px 94px 0px;
  }
  .contact-info .single-item{
    position: relative;
    padding: 0px 0px 0px 65px;
  }
  .contact-info .single-item:before{
    position: absolute;
    content: '';
    background: #e1e6eb;
    width: 1px;
    height: 100px;
    top: -26px;
    left: -43px;
  }
  .contact-info .column:first-child .single-item:before{
    display: none;
  }
  .contact-info .single-item .icon-box i:before{
    font-size: 45px;
    color: #00a1e4;
    margin: 0px;
    transition: all 500ms ease;
  }
  .contact-info .single-item:hover .icon-box i:before{
    color: #0f3a4a;
  }
  .contact-info .single-item .icon-box{
    position: absolute;
    left: 0px;
    top: 11px;
  }
  .contact-info .single-item .text{
    color: #0f3a4a;
    line-height: 26px;
  }

  .contact-info .single-item:before{
  position: absolute;
  content: '';
  background: #e1e6eb;
  width: 1px;
  height: 100px;
  top: -26px;
  left: -43px;
}
.contact-info .column:first-child .single-item:before{
  display: none;
}
.contact-info .single-item .icon-box i:before{
  font-size: 45px;
  color: #00a1e4;
  margin: 0px;
  transition: all 500ms ease;
}
.contact-info .single-item:hover .icon-box i:before{
  color: #0f3a4a;
}
.contact-info .single-item .icon-box{
  position: absolute;
  left: 0px;
  top: 11px;
}
.contact-info .single-item .text{
  color: #0f3a4a;
  line-height: 26px;
}

.icon-box{

  color: black;
  height: 50px;
  width:50px;
  margin-top: -9px;
  
  

}
.form-submit-button{
  background: #000;
  color: #fff;
}

