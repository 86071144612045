.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.swiper-button-next,
.swiper-button-prev {
    background-color: white;
    background-color: rgba(68, 67, 67, 0.777);
    top:40% !important;
    right: 10px !important;
    padding: 20px;
    /* left:10px; */
    color: #43c3ea !important;
    fill: var(---primeColor) !important;
    stroke: var(---primeColor) !important;
    backdrop-filter: blur(5px);
    border-radius: 5px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 1.4rem !important;
    text-transform: none!important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
}