/* ///////////////////////////////////////////// Section 1 ////////////////////////////////////////////////// */


.Gallery-banner {
  /* background-color: gray; */
  /* border: 1px solid red; */
  height: 60vh;
}

.Gallery-banner-text {
  position: relative;
  height: 55vh;
  justify-content: center;
  display: flex;
  align-items: center;
}

.Gallery-banner-text h1 {
  font-size: 30px;
  font-family: var(---TheamFont1);
  font-weight: bold;
  color: rgb(255, 255, 255);
  margin: 0px;
  padding: 0px;
  width: 30%;

}

.Text {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;

  width: 100%;
  clip-path: polygon(0 0, 100% 100%, 0% 100%);
}

.HB-Text {
  width: 100%;
  text-align: center;
}

.HB-Text p {
  /* color: white; */
  font-size: 30px;
  font-family: 'Courgette', cursive;
  color: white;
  font-family: var(---SubHeading);
}


/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper {
  max-width: 1350px;
  padding: 0px 60px;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
}

.wrapper img {
  cursor: pointer;
}

/* modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .6));
  display: flex;
  align-items: center;
}

.overlay img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}

.overlay>span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}

.overlay-arrows_left {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 45%;
  width: 50px;
  height: 50px;
  z-index: 999;
}

.overlay-arrows_left svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right {
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 0px;
  width: 50px;
  height: 50px;
  top: 45%;
  z-index: 999;
}


/* /////////////////////////// Section 2 /////////////////////////////////////////// */

.Image-Gallery {
  margin-top: 5vh;
}

.Image-Gallery-title {
  position: relative;
  margin-top: 10vh;
  margin-bottom: 10vh;

}

.Image-Gallery-title h4 {
  color: black;
  font-family: var(---HeadFont);
  font-size: 30px;
  text-transform: capitalize;
  font-weight: 600;
}

.Image-Gallery-title h3 {
  position: absolute;
  font-weight: bold;
  top: -51px;
  left: 81px;
  right: 0;
  font-family: 'Qwitcher Grypen', cursive;
  font-size: 85px;
  color: lightgrey;
  z-index: -1;
}

.ImageG-title {
  display: inline-block;
  background: #dee2e6;
  border-radius: 35px;
  margin-bottom: 15px;
}

.ImageG-title:hover {
  cursor: pointer;
}

.ImageG-title h4 {
  padding: 5px 15px 0px 15px;
}

.G-Images {
  margin: 20px 10px 20px 10px;
  height: 40vh;
  /* box-shadow: 0 0 5px #808080; */
  border-radius: 5px;
}

.G-Images img {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

@media(max-width:770px) {
  .Gallery-banner {
    /* background-color: gray; */
    /* border: 1px solid red; */
    height: 50vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100% 100%;
  }
}


@media(max-width:425px) {
  .Image-Gallery-title h3 {
    font-size: 55px;
    top: -27px;
    left: 0px;
  }

  /* .HB-Text {
    width: 100%;
    text-align: left;
    margin-left: 5vh !important;
  } */

  .Gallery-banner-text h1 {
    font-size: 24px;
    font-family: var(---TheamFont1);
    font-weight: bold;
    color: rgb(255, 255, 255);
    margin: 0px;
    padding: 0px;
    width: 100%;
  }

  .Gallery-banner {
    /* background-color: gray; */
    /* border: 1px solid red; */
    height: 40vh;
    background-size: cover;
  }

  .Gallery-banner-text {
    height: 40vh;
  }

}

/* //////////////////////////////////////////// Hotel Gallery Section /////////////////////////////////////////////// */

.Hotel-image-wrapper {
  height: 210px;
  position: relative;
  /* box-shadow: 0 0 5px #808080; */
  border-radius: 5px;
  margin-top: 10px;
  overflow: hidden;
}

.Hotel-image-wrapper:hover {
  transform: translateY(-5px);
  transition: transform .25s, -webkit-transform .25s;
}

.Hotel-image-wrapper:hover img {
  transform: scale(1.1);
}

.Hotel-image-wrapper img {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.Hotel-img-overlay {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .6));
  border-radius: 5px;
  color: rgb(218, 218, 218);
  border-radius: 5px;

}

.Hotel-img-overlay h3 {
  color: rgb(255 255 255 / 91%);
  font-family: 'Crimson Text', serif;
  font-size: 25px;
  font-weight: 100;
  margin: 5px;
  text-align: center;
}


@media(max-width:576px) {
  .Hotel-image-wrapper {
    height: auto;
  }
}
:where(.css-dev-only-do-not-override-1uweeqc).ant-image {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
}